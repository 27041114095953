import { CircularProgress } from '@mui/material'

const CircularLoading = (props: { text: string }) => {
    return (
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100vw", height: "100vh" }}>
            <span style={{ fontSize: "18px", marginBottom: "20px" }}>{props.text}</span>
            <CircularProgress />
        </div>
    )
}

export default CircularLoading