import styles from '../../stylesheets/EmailVerified.module.css'
import checkmark from "../../assets/verified.png"
import { Link } from 'react-router-dom'

const EmailVerified = () => {
    return (
        <>
            <div className={styles.wrapper}>
                <div className={styles.content_container}>
                    <img src={checkmark} alt="Check Mark" className={styles.verified_image} />
                    <h1 className={styles.success_text}>Email Verification Successful</h1>
                </div>
                <div className={styles.redirect_text_container}>
                    <h2>You can now sign in to the application</h2>
                </div>
                <Link to={"/login"}> <button className={styles.login_button}> Sign In </button> </Link>
            </div>
        </>
    )
}

export default EmailVerified