import { useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { APIKeyType } from "../../utils/general";
import { AuthLayout } from "../../components/AuthLayout";
import { Icon } from "../../components/Icon";
import { Breadcrumb } from "../../components/Breadcrumb";
import { Tab } from "../../components/Tab";
import {
  ScanHistoryTable,
  TransactionDetailsTable,
} from "./APIDetailsTabPanels";
import styles from "./ApiDetails.module.css";

export const ApiDetailsPage = () => {
  const location = useLocation();
  const keyDetails = useRef<APIKeyType>(location.state);

  const [editCallback, setEditCallback] = useState<boolean>(false);
  const [editExample, setEditExample] = useState<boolean>(false);
  const [callbackValue, setCallbackValue] =
    useState<string>("Example Callback");
  const [exampleValue, setExampleValue] = useState<string>("Example");

  const handleEditCallback = () => {
    setEditCallback(!editCallback);
  };

  const handleEditExample = () => {
    setEditExample(!editExample);
  };

  const handleSaveCallback = () => {
    // Save callback logic here
    setEditCallback(false);
  };

  const handleSaveExample = () => {
    // Save example logic here
    setEditExample(false);
  };

  const breadcrumbLinks = [
    {
      label: "Dashboard",
      to: "dashboard",
    },
    {
      label: keyDetails.current.key,
      to: `dashboard/${keyDetails.current.key}`,
    },
  ];

  const apiDetailsTabList = [
    {
      header: "Scan History",
      content: <ScanHistoryTable />,
    },
    {
      header: "Transaction Details",
      content: <TransactionDetailsTable />,
    },
  ];

  return (
    <AuthLayout pageH1="API Details">
      <Breadcrumb Links={breadcrumbLinks} />
      <div className={styles.main_container}>
        <div className={styles.data_wrapper}>
          <h2 className={styles.welcome_heading}>View API details</h2>
          <div className={styles.details_container}>
            <div className={styles.key_details}>
              <div className={styles.key}>
                ashdb <span className={styles.key_status}>Expired</span>
              </div>
              {editCallback ? (
                <div
                  className={styles.update_callback}
                  style={{
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  Update callback
                  <input
                    type="text"
                    value={callbackValue}
                    onChange={(e) => setCallbackValue(e.target.value)}
                    onBlur={handleSaveCallback}
                    className={styles.input_field}
                  />
                </div>
              ) : (
                <div className={styles.update_callback}>
                  Update callback
                  <button
                    className={styles.edit_icon}
                    onClick={handleEditCallback}
                  >
                    <Icon name={"Edit"} />
                  </button>
                </div>
              )}
              {editExample ? (
                <div
                  className={styles.update_example}
                  style={{
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  Example
                  <input
                    type="text"
                    value={callbackValue}
                    onChange={(e) => setCallbackValue(e.target.value)}
                    onBlur={handleSaveExample}
                    className={styles.input_field}
                  />
                </div>
              ) : (
                <div className={styles.update_example}>
                  Example
                  <button
                    className={styles.edit_icon}
                    onClick={handleEditExample}
                  >
                    <Icon name={"Edit"} />
                  </button>
                </div>
              )}
              <div className={styles.key_details_buttons}>
                <button
                  type="submit"
                  className={`button secondary ${styles.save_details_btn}`}
                >
                  Save Details
                </button>
                <button
                  type="submit"
                  className={`button secondary ${styles.delete_key_btn}`}
                >
                  Delete Key
                </button>
              </div>
            </div>
            <div className={styles.plan_details}>
              <div className={styles.plan_heading}>Plan Details</div>
              <div className={styles.plan_name}>Plan subscribed to: Plan 1</div>
              <div className={styles.billing_date}>
                Billing start and end date: date
              </div>
              <div className={styles.topup_wrapper}>
                <Link
                  to="/dashboard/topup-plans"
                  className={`button primary ${styles.topup_link}`}
                >
                  Add Credits
                </Link>
              </div>
            </div>
          </div>
        </div>
        <Tab tabList={apiDetailsTabList} />
      </div>
    </AuthLayout>
  );
};
