import { useEffect, useState } from "react";
import { PlanType } from "../../utils/general";
import { GET_PLAN_DETAILS } from "../../api/endpoints";
import { useCustomAxios } from "../../api/axios";
import { AuthLayout } from "../../components/AuthLayout";
import { SubscriptionCard } from "../../components/SubscriptionCard";
import styles from "./SubscriptionPlans.module.css";

export const SubscriptionPlansPage = () => {
  const [plans, setPlans] = useState<PlanType[]>([]);
  const { makeRequest } = useCustomAxios();

  useEffect(() => {
    makeRequest(
      [
        {
          url: GET_PLAN_DETAILS,
          method: "get",
          params: {
            plan_type: "subscription",
          },
        },
      ],
      (responseDataArr) => {
        const [plansData] = responseDataArr;
        setPlans(plansData);
      },
      (error) => console.error(error) // TODO: refactor error handling
    );
  }, []);

  return (
    <AuthLayout pageH1="Plans">
      <div className={styles.main_container}>
        <div className={styles.content_container}>
          <h2 className={styles.content_heading}>Subscription Plans</h2>
          <p className={styles.instructions}>
            Start building for free, then add a site plan to go live. Account
            plans unlock additional features.
          </p>
          <div className={styles.plans}>
            {plans.map((plan) => {
              const [planInfo, ...planData] = plan.plan_description.split("\n");
              return (
                <SubscriptionCard
                  key={plan.plan_id}
                  name={plan.plan_name}
                  info={planInfo}
                  price={+plan.plan_cost.$numberDecimal}
                  features={planData}
                />
              );
            })}
          </div>
        </div>
      </div>
    </AuthLayout>
  );
};
