import { HomePage } from "../pages/home";
import { SignupPage } from "../pages/signup";
import { ActivateAccountNotifierPage } from "../pages/activate-account-notifier";
import { ActivateAccountPage } from "../pages/activate-account";

export const unauthenticatedUserRoutes = [
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/signup",
    element: <SignupPage />,
  },
  {
    path: "/activate-account-notification",
    element: <ActivateAccountNotifierPage />,
  },
  {
    path: "/activate-account/:token",
    element: <ActivateAccountPage />,
  },
];
