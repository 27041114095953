import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  CREATE_KEY_ENDPOINT,
  GET_USER_KEYS_ENDPOINT,
} from "../../api/endpoints";
import { useCustomAxios } from "../../api/axios";
import { useAuth } from "../../context/AuthProvider";
import { APIKeyType } from "../../utils/general";
import { AuthLayout } from "../../components/AuthLayout";
import { Table } from "../../components/Table";
import { Icon } from "../../components/Icon";
import { getWelcomeText, getUserName } from "./DashboardText";
import styles from "./Dashboard.module.css";

// TODO: implement mobile component
export const DashboardPage = () => {
  const navigate = useNavigate();
  const { makeRequest } = useCustomAxios();
  const { userData, updateUserType } = useAuth();

  const [keys, setKeys] = useState<APIKeyType[] | null>(null);

  const keysTableHeaders = [
    {
      label: "API Keys",
      sortBy: false,
    },
    {
      label: "Name",
      sortBy: false,
    },
    {
      label: "Date Created",
      sortBy: true,
    },
    {
      label: "Status",
      sortBy: true,
    },
  ];

  const renderKeysTableBody = () => {
    return (
      <tbody>
        {keys?.map((apiKey) => {
          return (
            <tr key={apiKey.key}>
              <td>
                <Link to={`/dashboard/${apiKey.key}`} state={apiKey}>
                  {apiKey.key}
                </Link>
              </td>
              <td>{apiKey.key_name ?? ""}</td>
              <td>
                {new Date(
                  +apiKey.created_at.$date.$numberLong
                ).toLocaleDateString("en-US")}
              </td>
              <td>
                <strong data-key-status={apiKey.key_status}>
                  {apiKey.key_status[0].toUpperCase() +
                    apiKey.key_status.slice(1)}
                </strong>
              </td>
            </tr>
          );
        })}
      </tbody>
    );
  };

  // TODO: refactor error handling
  const handleServerError = (error: any) => {
    console.error(error);
  };

  const getNewAPIKey = async () => {
    if (userData?.user_type === "new") {
      const keyRequestData = {
        key_status: "trial",
        scan_limit: 100,
        batch_size: 50,
        plan_name: "trial",
      };
      makeRequest(
        [{ url: CREATE_KEY_ENDPOINT, method: "post", data: keyRequestData }],
        (responseDataArr) => {
          const [newKey] = responseDataArr;
          updateUserType("trial");
          setKeys([newKey]);
        },
        handleServerError
      );
    } else {
      navigate("/subscription-plans"); // TODO: update after lera-pay integration on plans page
    }
  };

  const fetchAPIKeys = (sortHeader: string, sortOrder: string) => {
    if (userData?.user_type !== "new") {
      // new user has no api key
      const sortParam =
        sortHeader === "Date Created" ? "created_at" : "key_status";
      const orderParam = sortOrder === "ascending" ? "asc" : "desc";
      makeRequest(
        [
          {
            url: GET_USER_KEYS_ENDPOINT,
            method: "get",
            params: {
              page: 1,
              limit: 10,
              sort_by: sortParam,
              order: orderParam,
            },
          },
        ],
        (responseDataArr) => {
          const [userKeysData] = responseDataArr;
          setKeys(userKeysData[0]);
        },
        handleServerError
      );
    }
  };

  return (
    <AuthLayout pageH1="Dashboard">
      <main className={styles.main_container}>
        <div className={styles.text_container}>
          <h2>
            {`Welcome, ${getUserName(
              userData?.first_name,
              userData?.last_name
            )}`}
          </h2>
          <p>{getWelcomeText(userData?.user_type)}</p>
          <button type="button" className="primary" onClick={getNewAPIKey}>
            <Icon name={`Add`} />
            <span>Create Key</span>
          </button>
        </div>
        <div className={styles.table_container}>
          <Table
            title="API Keys Overview"
            headers={keysTableHeaders}
            initialSortHeader="Date Created"
            fetchTableData={fetchAPIKeys}
            tableDataLength={keys?.length ?? 0}
            renderTableBody={renderKeysTableBody}
          />
        </div>
      </main>
    </AuthLayout>
  );
};
