// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SubmitButton_submit_btn__\\+A86Z {
  width: 100%;
  margin-top: 0.4rem;
  padding: 0.625rem;
  font-size: 1rem;
  border: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/SubmitButton/SubmitButton.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,kBAAkB;EAClB,iBAAiB;EACjB,eAAe;EACf,YAAY;AACd","sourcesContent":[".submit_btn {\n  width: 100%;\n  margin-top: 0.4rem;\n  padding: 0.625rem;\n  font-size: 1rem;\n  border: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"submit_btn": `SubmitButton_submit_btn__+A86Z`
};
export default ___CSS_LOADER_EXPORT___;
