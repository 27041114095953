import DocumentTitle from "react-document-title";
import React, { useEffect, useState, useRef, useId } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useCustomAxios } from "../../api/axios";
import { SIGNUP_ENDPOINT } from "../../api/endpoints";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Typography } from "@mui/material";
import { TextField } from "../../components/TextField";
import { PasswordField } from "../../components/PasswordField";
import { SubmitButton } from "../../components/SubmitButton";
import ErrorMessages from "../../components/ErrorMessages/errorMessage";
import { ErrorModal } from "../../components/Modals/errorModal";
import logo from "../../assets/lera.png";
import styles from "./Signup.module.css";

interface SignupDataType {
  firstname: string;
  lastname: string;
  email: string;
  password: string;
  password2: string;
}

export const SignupPage = () => {
  const id = useId();
  const signupDataIds = useRef<SignupDataType>({
    firstname: `firstname-${id}`,
    lastname: `lastname-${id}`,
    email: `email-${id}`,
    password: `password-${id}`,
    password2: `password2-${id}`,
  });
  const [formData, setFormData] = useState<SignupDataType>({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    password2: "",
  });
  const [formErrors, setFormErrors] = useState({} as SignupDataType);
  const [formErrorsCount, setFormErrorsCount] = useState<number>(0);
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const errorModalMsg = useRef<string>("");

  const mainRef = useRef<HTMLDivElement>(null);
  const errorsRef = useRef<HTMLDivElement>(null);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  //TODO: refactor email validation, implement password strength validation
  const validateSignupData = () => {
    let validationErrors = {} as SignupDataType;
    let errorsCount = 0;
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (formData.firstname === "") {
      validationErrors.firstname = "You must enter your first name";
      errorsCount++;
    }

    if (formData.lastname === "") {
      validationErrors.lastname = "You must enter your last name";
      errorsCount++;
    }

    if (formData.email === "") {
      validationErrors.email = "You must enter an email address";
      errorsCount++;
    } else if (!regex.test(formData.email)) {
      validationErrors.email = "You must enter a valid email address";
      errorsCount++;
    }

    if (formData.password === "") {
      validationErrors.password = "You must enter a password";
      errorsCount++;
    } else if (formData.password.length < 4) {
      // user is not made aware of this constraint
      validationErrors.password = "You must enter a valid password";
      errorsCount++;
    }

    if (formData.password === "") {
      validationErrors.password2 = "You must re-enter your password";
      errorsCount++;
    } else if (formData.password2 !== formData.password) {
      validationErrors.password2 = "Your passwords do not match";
      errorsCount++;
    }

    return { errorsCount, validationErrors };
  };

  const { isLoading, makeRequest } = useCustomAxios();
  const navigate = useNavigate();

  const handleUserNavigateOnSignupSuccess = () => {
    navigate("/activate-account-notification");
  };

  // TODO: refactor signup error handling
  const handleServerError = (error: any) => {
    if (error.response) {
      errorModalMsg.current = error.response.data.Error;
    } else {
      errorModalMsg.current =
        "You are offline or may be server is facing some technical issues. Please try again.";
    }
    setShowErrorModal(true);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { errorsCount, validationErrors } = validateSignupData();
    if (errorsCount > 0) {
      setFormErrors(validationErrors);
      setFormErrorsCount(errorsCount);
    } else {
      const signupData = {
        first_name: formData.firstname,
        last_name: formData.lastname,
        email: formData.email,
        password_hash: formData.password,
      };
      const signupHeaders = {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET",
        "Access-Control-Allow-Headers": "Content-Type, Authorization",
      };
      makeRequest(
        [
          {
            url: SIGNUP_ENDPOINT,
            method: "post",
            data: signupData,
            headers: signupHeaders,
          },
        ],
        handleUserNavigateOnSignupSuccess,
        handleServerError
      );
    }
  };

  const handleErrorModalClose = () => {
    errorModalMsg.current = "";
    setShowErrorModal(false);
  };

  useEffect(() => {
    if (formErrorsCount === 0 && mainRef.current) {
      mainRef.current.focus();
    } else if (formErrorsCount > 0 && errorsRef.current) {
      errorsRef.current.focus();
    }
  }, [formErrorsCount]);

  return (
    <DocumentTitle title="Create Account - Lera cloud">
      <div className={styles.signup_container}>
        <header>
          <Link to="/">
            <img src={logo} alt="Lera Homepage" className={styles.logo} />
          </Link>
        </header>
        <main>
          <div className={styles.signup_intro}>
            <h1 className={styles.signup_text} tabIndex={-1} ref={mainRef}>
              Create your account today
            </h1>
            <p className={styles.signin_redirect_wrapper}>
              Or{" "}
              <Link to="/login" className={styles.signin_redirect}>
                {" "}
                log in to your account
              </Link>
            </p>
          </div>
          <div className={styles.signup_box}>
            {formErrorsCount > 0 && (
              <ErrorMessages
                messages={formErrors}
                ids={signupDataIds.current}
                count={formErrorsCount}
                ref={errorsRef}
              />
            )}
            <form
              onSubmit={handleSubmit}
              className={styles.input_form}
              noValidate
            >
              <p>All fields are required</p>
              <TextField
                id={signupDataIds.current.firstname}
                label="First Name"
                name="firstname"
                value={formData.firstname}
                setValue={handleInputChange}
                autoComplete="given-name"
                errMsg={formErrors.firstname ?? ""}
                inputStyle={styles.input_box}
              />
              <TextField
                id={signupDataIds.current.lastname}
                label="Last Name"
                name="lastname"
                value={formData.lastname}
                setValue={handleInputChange}
                autoComplete="family-name"
                errMsg={formErrors.lastname ?? ""}
                inputStyle={styles.input_box}
              />
              <TextField
                id={signupDataIds.current.email}
                label="Email Address"
                type="email"
                name="email"
                value={formData.email}
                setValue={handleInputChange}
                autoComplete="email"
                errMsg={formErrors.email ?? ""}
                inputStyle={styles.input_box}
              />
              <fieldset>
                <legend>Create Password</legend>
                <PasswordField
                  id={signupDataIds.current.password}
                  label="Password"
                  name="password"
                  value={formData.password}
                  setValue={handleInputChange}
                  autoComplete="new-password"
                  toggleLabel="Show Password"
                  errMsg={formErrors.password ?? ""}
                  inputStyle={styles.input_box}
                />
                <PasswordField
                  id={signupDataIds.current.password2}
                  label="Re-Enter Password"
                  name="password2"
                  value={formData.password2}
                  setValue={handleInputChange}
                  autoComplete="new-password"
                  toggleLabel="Show Re-entered Password"
                  errMsg={formErrors.password2 ?? ""}
                  inputStyle={styles.input_box}
                />
              </fieldset>
              <SubmitButton label="Create Account" disabled={isLoading} />
            </form>
          </div>
        </main>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
          <Typography role="alert">Loading</Typography>
        </Backdrop>
        {showErrorModal && (
          <ErrorModal
            message={errorModalMsg.current}
            open={showErrorModal}
            onClose={handleErrorModalClose}
          />
        )}
      </div>
    </DocumentTitle>
  );
};
