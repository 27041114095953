interface TextFieldProps {
  id: string;
  label: string;
  type?: string; // type defaults to text
  name: string;
  value: string;
  setValue: (e: React.ChangeEvent<HTMLInputElement>) => void;
  autoComplete: string;
  errMsg: string;
  inputStyle: any;
}

export const TextField = ({
  id,
  label,
  type = "text",
  name,
  value,
  setValue,
  autoComplete,
  errMsg,
  inputStyle,
}: TextFieldProps) => {
  return (
    <div className={inputStyle}>
      <label htmlFor={id}>{label}</label>
      <input
        id={id}
        type={type}
        name={name}
        value={value}
        onChange={setValue}
        aria-describedby={`err-${id}`}
        autoComplete={autoComplete}
        aria-invalid={errMsg !== ""}
      />
      {errMsg && <span id={`err-${id}`}>{errMsg}</span>}
    </div>
  );
};
