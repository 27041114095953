import styles from "./SubmitButton.module.css";

interface ButtonProps {
  label?: string;
  disabled?: boolean;
}

export const SubmitButton = ({ label = "Submit", disabled = false }: ButtonProps) => {
  return (
    <button type="submit" disabled={disabled} className={`primary ${styles.submit_btn}`}>
      {label}
    </button>
  );
};
