// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EmailVerified_wrapper__1USSD {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    align-items: center;
}

.EmailVerified_verified_image__X7pyt {
    height: 100px;
    width: auto;
    margin: 5px;
}

.EmailVerified_content_container__0BJO9 {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: -moz-fit-content;
    height: fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border: 1px solid #08dc6c;
    border-radius: 5px;
    margin-top: 300px;
}

.EmailVerified_success_text__sIPzI {
    margin: 10px;
    font-weight: 400;
}

.EmailVerified_redirect_text_container__k1sfW {
    width: 70%;
    text-align: center;
    font-weight: 300;
    margin-top: 20px;
    margin-bottom: 10px;
}

.EmailVerified_redirect_text_container__k1sfW > h2 {
    font-weight: 300;
}

.EmailVerified_login_button__drWva {
    border: none;
    background-color: #cc5ca4;
    width: 200px;
    padding: 10px;
    border-radius: 5px;
    color: white;
    font-size: 20px;
    cursor: pointer;
}

.EmailVerified_login_button__drWva:hover {
    background-color: #d877b6;
}`, "",{"version":3,"sources":["webpack://./src/stylesheets/EmailVerified.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,WAAW;IACX,WAAW;AACf;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,wBAAmB;IAAnB,mBAAmB;IACnB,uBAAkB;IAAlB,kBAAkB;IAClB,yBAAyB;IACzB,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IACI,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,UAAU;IACV,kBAAkB;IAClB,gBAAgB;IAChB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,yBAAyB;IACzB,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,YAAY;IACZ,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".wrapper {\n    display: flex;\n    flex-direction: column;\n    width: 100vw;\n    height: 100vh;\n    align-items: center;\n}\n\n.verified_image {\n    height: 100px;\n    width: auto;\n    margin: 5px;\n}\n\n.content_container {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    height: fit-content;\n    width: fit-content;\n    border: 1px solid #08dc6c;\n    border-radius: 5px;\n    margin-top: 300px;\n}\n\n.success_text {\n    margin: 10px;\n    font-weight: 400;\n}\n\n.redirect_text_container {\n    width: 70%;\n    text-align: center;\n    font-weight: 300;\n    margin-top: 20px;\n    margin-bottom: 10px;\n}\n\n.redirect_text_container > h2 {\n    font-weight: 300;\n}\n\n.login_button {\n    border: none;\n    background-color: #cc5ca4;\n    width: 200px;\n    padding: 10px;\n    border-radius: 5px;\n    color: white;\n    font-size: 20px;\n    cursor: pointer;\n}\n\n.login_button:hover {\n    background-color: #d877b6;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `EmailVerified_wrapper__1USSD`,
	"verified_image": `EmailVerified_verified_image__X7pyt`,
	"content_container": `EmailVerified_content_container__0BJO9`,
	"success_text": `EmailVerified_success_text__sIPzI`,
	"redirect_text_container": `EmailVerified_redirect_text_container__k1sfW`,
	"login_button": `EmailVerified_login_button__drWva`
};
export default ___CSS_LOADER_EXPORT___;
