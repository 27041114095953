// const BASE_URL = "http://127.0.0.1:9115/api"
const BASE_URL = "https://api.lera.cloud/api";

const SIGNUP_ENDPOINT = BASE_URL + "/signup";
const LOGOUT_ENDPOINT = BASE_URL + "/logout";
const LOGIN_ENDPOINT = BASE_URL + "/login";
const SESSION_LOGIN_ENDPOINT = BASE_URL + "/session_login";
const ACTIVATE_ACCOUNT_ENDPOINT = BASE_URL + "/activate_account";
const RETRY_VERIFICATION_ENDPOINT = BASE_URL + "/resend-email";
const FORGOT_PASSWORD_ENDPOINT = BASE_URL + "/forgot_password";
const RESET_PASSWORD_ENDPOINT = BASE_URL + "/reset_password";
const CREATE_KEY_ENDPOINT = BASE_URL + "/create_key";
const GET_USER_KEYS_ENDPOINT = BASE_URL + "/userkeys";
const GET_PLAN_DETAILS = BASE_URL + "/plan_details";

export {
  LOGIN_ENDPOINT,
  SIGNUP_ENDPOINT,
  LOGOUT_ENDPOINT,
  ACTIVATE_ACCOUNT_ENDPOINT,
  RETRY_VERIFICATION_ENDPOINT,
  FORGOT_PASSWORD_ENDPOINT,
  RESET_PASSWORD_ENDPOINT,
  SESSION_LOGIN_ENDPOINT,
  CREATE_KEY_ENDPOINT,
  GET_USER_KEYS_ENDPOINT,
  GET_PLAN_DETAILS,
};
