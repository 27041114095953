import EmailVerified from "../../components/EmailVerified";
import { ACTIVATE_ACCOUNT_ENDPOINT } from "../../api/endpoints";
import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CircularLoading from "../../components/CircularLoading";
import RetryVerificationPage from "../../components/RetryVerificationPage";

export const ActivateAccountPage = () => {
  let { token } = useParams();
  const [status, setStatus] = useState<"loading" | "error" | "verified">(
    "loading"
  );
  
  useEffect(() => {
    const activateAccount = async () => {
      try {
        if (!token) throw new Error("Token not found");
        let data = { token: decodeURI(token) };
        let res = await axios.post(ACTIVATE_ACCOUNT_ENDPOINT, data);
        setStatus("verified");
      } catch (error: any) {
        setStatus("error");
      }
    };
    activateAccount();
  }, [token]);

  const content = () => {
    if (status === "loading") {
      <CircularLoading text="Verifying Email" />;
    }
    if (status === "verified") {
      return <EmailVerified />;
    } else if (status === "error") {
      return <RetryVerificationPage />;
    }
  };

  return <div>{content()}</div>;
};
