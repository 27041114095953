import { useState } from "react";
import { Table } from "../../components/Table";

export const ScanHistoryTable = () => {
  const [scans, setScans] = useState<[]>([]);

  const scanHistoryTableHeaders = [
    {
      label: "Scan ID",
      sortBy: false,
    },
    {
      label: "Date Created",
      sortBy: true,
    },
    {
      label: "Credit Balance",
      sortBy: true,
    },
  ];

  const renderScanHistoryTableBody = () => <></>;

  const fetchScans = () => {
    console.log("fetching data"); // placeholder for function body
  };

  return (
    <Table
      title="Scan History"
      showTitle={false}
      headers={scanHistoryTableHeaders}
      initialSortHeader="Date Created"
      fetchTableData={fetchScans}
      tableDataLength={scans?.length ?? 0}
      renderTableBody={renderScanHistoryTableBody}
    />
  );
};

export const TransactionDetailsTable = () => {
  const [transactions, setTransactions] = useState<[]>([]);

  const transactionDetailsTableHeaders = [
    {
      label: "Order ID",
      sortBy: false,
    },
    {
      label: "Date",
      sortBy: true,
    },
    {
      label: "Credit",
      sortBy: true,
    },
    {
      label: "Status",
      sortBy: true,
    },
  ];

  const renderTransactionDetailsTableBody = () => <></>;

  const fetchTransactions = () => {
    console.log("fetching data"); // placeholder for function body
  };

  return (
    <Table
      title="Transaction Details"
      showTitle={false}
      headers={transactionDetailsTableHeaders}
      initialSortHeader="Date"
      fetchTableData={fetchTransactions}
      tableDataLength={transactions?.length ?? 0}
      renderTableBody={renderTransactionDetailsTableBody}
    />
  );
};
