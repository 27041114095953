import { DashboardPage } from "../pages/dashboard";
import { ApiDetailsPage } from "../pages/api-details";
import { SubscriptionPlansPage } from "../pages/subscription-plans";
import { TopupPlansPage } from "../pages/topup-plans";

export const authenticatedUserRoutes = [
  // allow authenticated user access to dashboard page from the root and dashboard routes
  {
    path: "/",
    element: <DashboardPage />,
  },
  {
    path: "/dashboard",
    children: [
      {
        index: true,
        element: <DashboardPage />,
      },
      {
        path: ":api-key",
        element: <ApiDetailsPage />,
      },
      {
        path: "topup-plans",
        element: <TopupPlansPage />,
      },
    ],
  },
  {
    path: "/subscription-plans",
    element: <SubscriptionPlansPage />,
  },
];
