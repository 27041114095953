import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { PlanType } from "../../utils/general";
import { AuthLayout } from "../../components/AuthLayout";
import { Icon } from "../../components/Icon";
import styles from "./TopupPlans.module.css";

export const TopupPlansPage = () => {
  const location = useLocation();
  const { planId, planType, topupPlans } =
    (location.state as {
      planId: string;
      planType: string;
      topupPlans: PlanType[];
    }) || {};
  const [filteredTopupPlans, setFilteredTopupPlans] = useState<PlanType[]>([]);

  useEffect(() => {
    if (planType === "subscription") {
      setFilteredTopupPlans(topupPlans);
    }
  }, [planType, topupPlans]);
  return (
    <AuthLayout pageH1="Billing">
      <div className={styles.main_container}>
        <div className={styles.data_wrapper}>
          <h2 className={styles.pricing_heading}>Pay as you go</h2>
          <p className={styles.instrucrions}>
            To pay online, please select the number of credits you wish to
            purchase:
          </p>
          <div className={styles.payment_option}>
            <label className={styles.dropdown_label}>Select Amount</label>
            <select className={styles.dropdown_select}>
              {filteredTopupPlans.map((plan) => (
                <option key={plan.plan_id} className={styles.dropdown_option}>
                  ${plan.plan_cost.$numberDecimal} per {plan.scan_limit} scans
                </option>
              ))}
              {/* <option className={styles.dropdown_option}>$10 per 1000 scans</option>
              <option className={styles.dropdown_option}>$20 per 2000 scans</option>
              <option className={styles.dropdown_option}>$75 per 7,500 scans</option>
              <option className={styles.dropdown_option}>$150 per 15,000 scans</option>
              <option className={styles.dropdown_option}>$250 per 25,000 scans</option> */}
            </select>
          </div>
          <Link to="" className={styles.plan_link}>
            Proceed to Payment
            <Icon name="ArrowRight" />
          </Link>
        </div>
      </div>
    </AuthLayout>
  );
};
