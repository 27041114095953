import Icons from "../../assets/sprite.svg";
import { IconType } from "./IconType";
import "./Icon.module.css";

interface IconProps {
  name: IconType;
}

export const Icon = ({ name }: IconProps) => {
  return (
    <svg viewBox="0 0 24 24" aria-hidden="true">
      <use href={`${Icons}#${name}`} />
    </svg>
  );
};
