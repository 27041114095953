import styles from "../../stylesheets/RetryForm.module.css"
import { useNavigate } from "react-router-dom"

const RetryVerificationPage = () => {
  const navigate = useNavigate()

  const handleClick = () => {
    navigate("/resend-verification")
  }

  const renderer = () => {
      return (<div className={styles.wrapper}>
        <div className={styles.content_container}>
          <h1 className={styles.failure_text}>Email Verification Unsuccessful</h1>
        </div>
        <div className={styles.redirect_text_container}>
          <h2>Click here to retry</h2>
        </div>
        <button className={styles.login_button} onClick={handleClick}> Retry </button>
      </div>)
    
  }

  return (
    <>
      {renderer()}
    </>
  )
}
export default RetryVerificationPage